import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>👋 Hello, I'm Akshay Mahajan</h2>
    <p>{`A software engineer based in New Delhi, India. Co-founder at `}<a parentName="p" {...{
        "href": "https://letx.co"
      }}>{`LetX`}</a>{`. Currently handling front end development & technology innovations. I have a strong flair of focused analytical skills, interpersonal strength, far-sightedness clubbed with a good sense of humor.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      